<template>
    <div class="detailsWrap">
        <!-- <p style="border-bottom: 1px solid #ccc; padding-bottom: 10px">
            <router-link to="/lbs-place">
                <a-icon type="rollback" /> 回到LBS定位
            </router-link>
        </p> -->
        <div>
            <div style="display: flex; margin-bottom: 20px">
                <span class="detailsTitle">设备位置</span>
                <div class="recordWrap">
                    <ul style="border-right: 1px solid #ccc">
                        <li style="margin-bottom: 20px">
                            设备名
                        </li>
                        <li>A1</li>
                    </ul>
                    <ul>
                        <li style="margin-bottom: 20px">
                            位置记录
                        </li>
                        <li>1条</li>
                    </ul>
                </div>
            </div>
            <div style="position: relative">
                <Map :is-show-site="false" :is-show-input="false" />
                <div class="searchWrap">
                    <div style="margin-bottom: 12px">
                        <DateRangePicker :tip="['请选择开始日期', '请选择结束日期']" />
                        <span class="reload">
                            <a-icon type="sync" /> 刷新位置
                        </span>
                    </div>
                    <a-table style="margin-bottom: 20px"
                             :locale="{emptyText: '暂无数据'}"
                             table-layout="auto" :columns="columns" :pagination="false" row-key="id" :data-source="data" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Map from "@/components/Map"
import DateRangePicker from "@/components/DateRangePicker"
const columns = [
    {
        title: '时间',
        dataIndex: 'time',
        key: 'time'
    },
    {
        title: '地址',
        dataIndex: 'address',
        key: 'address'
    },
    {
        title: '基站信息',
        dataIndex: 'info',
        key: 'info'
    },
]
const data = [
    {
        id: 0,
        address: '重庆市渝北区黄山大道',
        info: 'sdefers',
        time: '2021-1-1'
    },
    {
        id: 1,
        address: '重庆市渝北区黄山大道',
        info: 'sdefers',
        time: '2021-1-1'
    },
    {
        id: 2,
        address: '重庆市渝北区黄山大道',
        info: 'sdefers',
        time: '2021-1-1'
    }
]
export default {
    name: "EquipSite",
    components: {
        Map,
        DateRangePicker
    },
    data() {
        return {
            columns,
            data
        }
    }
}
</script>

<style lang="scss" scoped>
.detailsWrap {
    margin: 30px;

    a {
        color: #333;
    }

    .detailsTitle {
        font-size: 20px;
        font-weight: bold;
    }
    ::v-deep .map {
        height: 600px;
    }
    .recordWrap {
        display: flex;
        border: 1px solid #ccc;
        padding: 30px 0;
        background: #eee;
        margin-left: 60px;
        ul {
            width: 200px;
            margin: 0;
            text-align: center;
        }
    }
    .searchWrap {
        position: absolute;
        top: 0;
        left: 0;
        padding: 20px;
        background: #fff;
        border: 1px solid #ccc;
        z-index: 999;
        .reload {
            color: #1890ff;
            margin-left: 20px;
            cursor: pointer;
        }
    }
}
</style>

