var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detailsWrap" }, [
    _c("div", [
      _vm._m(0),
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c("Map", {
            attrs: { "is-show-site": false, "is-show-input": false },
          }),
          _c(
            "div",
            { staticClass: "searchWrap" },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "12px" } },
                [
                  _c("DateRangePicker", {
                    attrs: { tip: ["请选择开始日期", "请选择结束日期"] },
                  }),
                  _c(
                    "span",
                    { staticClass: "reload" },
                    [
                      _c("a-icon", { attrs: { type: "sync" } }),
                      _vm._v(" 刷新位置 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-table", {
                staticStyle: { "margin-bottom": "20px" },
                attrs: {
                  locale: { emptyText: "暂无数据" },
                  "table-layout": "auto",
                  columns: _vm.columns,
                  pagination: false,
                  "row-key": "id",
                  "data-source": _vm.data,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "flex", "margin-bottom": "20px" } },
      [
        _c("span", { staticClass: "detailsTitle" }, [_vm._v("设备位置")]),
        _c("div", { staticClass: "recordWrap" }, [
          _c("ul", { staticStyle: { "border-right": "1px solid #ccc" } }, [
            _c("li", { staticStyle: { "margin-bottom": "20px" } }, [
              _vm._v(" 设备名 "),
            ]),
            _c("li", [_vm._v("A1")]),
          ]),
          _c("ul", [
            _c("li", { staticStyle: { "margin-bottom": "20px" } }, [
              _vm._v(" 位置记录 "),
            ]),
            _c("li", [_vm._v("1条")]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }